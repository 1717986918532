import produce from "immer";
import {
  SET_LANG_LIBRARY,
  SET_LISTENING_HISTORY,
  SET_PLAYLISTS,
  SET_USER_LIKED_TRACKS,
  SET_VIALMA_PLAYLIST,
  SET_VIALMA_PLAYLIST_LIKED,
  SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS,
  SET_USER_LIKED_ALBUMS,
  SET_USER_LIKED_VIDEOS,
  SET_USER_LIKED_COLLECTIONS,
  SET_USER_LIKED_ARTICLES,
  SET_USER_PLAYLIST_DETAILS,
} from "./constants";

const initialState = {
  langLibrary: "en",
  listeningHistory: null,
  playlists: null,
  vialmaPlaylists: null,
  vialmaPlaylistLiked: 0,
  userLikedTracks: null,
  userLikedAlbums: null,
  userLikedVideos: null,
  userLikedCollections: null,
  userLikedArticles: null,
  playlistDetails: null,
};

const libraryReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_LANG_LIBRARY:
        draft.langLibrary = action.langLibrary;
        break;
      case SET_LISTENING_HISTORY:
        draft.listeningHistory = action.listeningHistory;
        break;
      case SET_PLAYLISTS:
        draft.playlists = action.playlists;
        break;
      case SET_VIALMA_PLAYLIST_LIKED:
        draft.vialmaPlaylistLiked = action.vialmaPlaylistLiked;
        break;
      case SET_VIALMA_PLAYLIST_LIKED_PLUSMINUS:
        draft.vialmaPlaylistLiked = action.isPlus ? state.vialmaPlaylistLiked + 1 : state.vialmaPlaylistLiked - 1;
        break;
      case SET_USER_LIKED_TRACKS:
        let userLikedTracks = Array.isArray(action.userLikedTracks) ? [...action.userLikedTracks] : [];
        draft.userLikedTracks = userLikedTracks;
        break;
      case SET_VIALMA_PLAYLIST:
        draft.vialmaPlaylists = action.vialmaPlaylists;
        draft.vialmaPlaylistLiked = action.vialmaPlaylists?.length;
        break;
      case SET_USER_LIKED_ALBUMS:
        draft.userLikedAlbums = action.userLikedAlbums;
        break;
      case SET_USER_LIKED_VIDEOS:
        draft.userLikedVideos = action.userLikedVideos;
        break;
      case SET_USER_LIKED_ARTICLES:
        draft.userLikedArticles = action.userLikedArticles;
        break;
      case SET_USER_LIKED_COLLECTIONS:
        draft.userLikedCollections = action.userLikedCollections;
        break;
      case SET_USER_PLAYLIST_DETAILS:
        draft.playlistDetails = action.playlistDetails;
        break;
      default:
        return;
    }
  });
};

export default libraryReducer;
