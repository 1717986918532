import { createSelector } from 'reselect';
import { REDUCER_KEY } from './constants';

const getPartner = state => state[REDUCER_KEY].partner;
const getPartnerLoading = state => state[REDUCER_KEY].partnerLoading;
const getPartnerPages = state => state[REDUCER_KEY].pages;

export const getPartnerSelector = createSelector(
  getPartner,
  partner => partner
);

export const getPartnerLoadingSelector = createSelector(
  getPartnerLoading,
  partnerLoading => partnerLoading
);

export const getPartnerPagesSelector = createSelector(
  getPartnerPages,
  pages => pages
);
