export const JAZZ_NAME = "Jazz";
export const CLASSICAL_NAME = "Classical";
export const UNIVERSE_AS_FILTER = "&universeAsFilter=true";
export const CLASSICAL_UNIVERSE = "&universe=Classical";
export const JAZZ_UNIVERSE = "&universe=Jazz";
export const DEFAULT_MEDIA_ROW_COUNT = "4";
//PAGE ORIGINS FOR MINIPLAYER
export const PAGE_ORIGIN_SEARCH_RESULTS = "search_results";
export const PAGE_ORIGIN_ARTIST = "artist";
export const PAGE_ORIGIN_USER_PLAYLIST = "user_playlist";
export const PAGE_ORIGIN_PLAYLIST = "playlist";
export const PAGE_ORIGIN_PODCASTS = "podcasts";
export const PAGE_ORIGIN_UNKNOWN = "unknown";
export const PAGE_ORIGIN_ARTICLE = "article";
export const PAGE_ORIGIN_PROFILE = "profile";
export const PAGE_ORIGIN_COMPOSER = "composer";
export const PAGE_ORIGIN_WORK = "work";
export const PAGE_ORIGIN_LISTENING_HISTORY = "listening_history";
export const PAGE_ORIGIN_WEBPLAYER = "webplayer";
export const PAGE_ORIGIN_ALBUM = "album";
export const PAGE_ORIGIN_CHAPTER = "chapter";
export const PAGE_ORIGIN_HOME_BANNER = "home_banner";
export const PAGE_ORIGIN_QUIZ_RECOMMENDATION = "quiz_recommendation";
export const PAGE_ORIGIN_NEWSFEED_ITEM = "newsfeed_item";
export const PAGE_ORIGIN_COMPOSERS_PAGE = "page_composers";
export const PAGE_ORIGIN_FAVOURITE_TRACK = "favourite_track";
//MEDIACARD TYPES and
//some CONTENT CATEGORY TYPES
//some PILL TYPES
export const ALBUM = "album";
export const FEATURED_ALBUM = "featuredAlbum";
export const ARTICLE = "article";
export const PODCAST = "podcast";
export const CHAPTER = "chapter";
export const PLAYLIST = "playlist";
export const VIDEO = "video";
export const COLLECTION = "collection";
export const ARTIST = "artist";
export const DISPLAY_ARTIST = "displayArtist";
export const WORK = "work";
export const TRACK = "track";
export const PERSONAL_PLAYLIST = "personalTracks";
export const RECENT_TRACKS = "recent-tracks";
export const DETAILED_PAGE_TYPE = "detailedPage"
export const DETAILED_PAGE = {
  personal: "personalTracks",
  favorited: "favouritedTracks",
  recentTracks: "recentListenedTracks",
};
export const PLAUSIBLE_PROPS = {
  LIST: "From track list",
  PLAY_QUEUE: "From play-queue",
  MINIPLAYER: "From miniplayer",
  EXPANDED_PLAYER: "From expanded player",
  SAVE_BUTTON: "Save button from banner",
};
//QUIZ TYPES
export const QUIZ_ONBOARDING = "onboarding";
export const QUIZ_PLAYLISTS_PAGE = "playlists";
export const QUIZ_ARTISTS_PAGE = "artists";
export const QUIZ_ARTICLES_PAGE = "articles";
export const QUIZ_PODCASTS_PAGE = "podcasts";
export const QUIZ_VIDEOS_PAGE = "videos";
export const QUIZ_GENERAL = "general";
export const QUIZ_HOMEPAGE = "homepage";
export const QUIZ_COMPOSERS_PAGE = "composers";
export const QUIZ_COLLECTIONS_PAGE = "collections";

//COLOURS
export const COLOUR_WHITE = "#ffffff";
export const COLOUR_RED = "#dd4e52";
export const COLOUR_BLUE = "#4866e0";
export const COLOUR_BLACK = "#141140";

//API CALL BATCH COUNT
export const ARTICLES_BATCH_COUNT = 12;
export const VIDEOS_BATCH_COUNT = 12;

//BILLING PLAN HASHES
export const BILLINGPLAN_HASHES = {
  CLASSICAL: {
    MONTHLY: "8210Db38d3066650b0fDBE2110a62227",
    YEARLY: "d7473111a38CebA46C094b6b11247b5F",
  },
  ALLVIALMA: {
    MONTHLY: "7fA2aa3319fEC08c28b11fD1F6635803",
    YEARLY: "0066Fe39204b96c2C22aEbBddB824e93",
  },
  JAZZ: {
    MONTHLY: "dDf5428f532aCF949BbA5a96CB238F7d",
    YEARLY: "8501abB83916F00C140bda52E8910048",
  },
  NOPLAN: {
    NOPLAN: "ba58Ae9D3DD88B407c6d5C200801d247",
  },
};

//SUBCRIPTION QUERY PARAMS
export const SUB_QUERY_PARAMS = {
  PLAN: {
    CLASSICAL: "classical",
    ALLVIALMA: "allvialma",
    JAZZ: "jazz",
  },
  TERM: {
    YEARLY: "yearly",
    MONTHLY: "monthly",
  },
};

// Plan short names (from server-side)
export const PLAN_NAMES_SHORT = {
  CLASSICAL: "Vialma Classic",
  ALL_VIALMA: "All Vialma",
  JAZZ: "Vialma Jazz",
};

//ARTISTS ROLES
export const ROLES_BY_CATEGORY = {
  artists: [
    { id: 12, name: "Artist" },
    { id: 14, name: "Ensemble" },
    { id: 3, name: "Conductor" },
    { id: 13, name: "Orchestra" },
    { id: 15, name: "Choir" },
    { id: 2, name: "Performer" },
    { id: 5, name: "Accompaniment" },
    { id: 10, name: "Performer jazz" },
    { id: 11, name: "Other performer jazz" },
    { id: 21, name: "Soloist" },
    { id: 23, name: "FeaturedArtist" },
    { id: 25, name: "Featuring" },
    { id: 12, name: "Artiste" },
    { id: 3, name: "Chef d'orchestre" },
    { id: 51, name: "Chorale" },
    { id: 53, name: "Contre-ténor" },
    { id: 54, name: "Ténor" },
    { id: 55, name: "Baryton" },
    { id: 52, name: "Basse" },
    { id: 56, name: "Directeur" },
    { id: 57, name: "BassVocals" },
    { id: 60, name: "MezzoSopranoVocals" },
    { id: 63, name: "SopranoSolo" },
    { id: 61, name: "TenorSolo" },
    { id: 59, name: "UserDefined" },
    { id: 58, name: "BaritoneVocals" },
    { id: "", name: "AltoVocals" },
    { id: 71, name: "Contributing Artist" },
    { id: "", name: "StudioPersonnel" },
  ],
  composers: [
    { id: 1, name: "Composer" },
    { id: 4, name: "Arranger" },
    { id: 9, name: "Composer jazz" },
    { id: 19, name: "Lyricist" },
    { id: 24, name: "MusicPublisher" },
    { id: 22, name: "Writer" },
    { id: "", name: "Contributor" },
    { id: 26, name: "ComposerLyricist" },
  ],
  others: [
    { id: 16, name: "Engineer" },
    { id: 17, name: "Producer" },
    { id: 18, name: "Editor" },
    { id: 0, name: "Unknown" },
    { id: 20, name: "" },
  ],
};

export const mobileAppUrl = {
  en: "https://mobile.vialma.com/app/home",
  fr: "https://mobile.vialma.com/app/accueil",
};

export const PARENT_DESKTOP_DOCKED_PLAYER = "parent-docked-desktop-player";
export const PARENT_MOBILE_DOCKED_PLAYER = "parent-docked-mobile-player";
export const PARENT_DESKTOP_EXPANDED_PLAYER = "parent-expanded-desktop-player";
export const PARENT_MOBILE_EXPANDED_PLAYER = "parent-expanded-mobile-player";

//Dummy playlistId
export const DUMMY_PLAYLISTID = 123;

//Login errors for translations
export const LOGIN_ERRORS = {
  USERNAME_PASSWORD_MATCH_ERROR: "Username or password don't match!",
  USER_UNAUTHORIZED_ERROR: "You are not authorized to log in to this site",
};
