import produce from "immer";
import { SET_PAGES, SET_PARTNER, SET_PARTNER_LOADING } from "./constants";

const initialState = {
  partner: null,
  partnerLoading: false,
  pages: null
};

const partnerReducer = function (state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_PARTNER_LOADING:
        draft.partnerLoading = action.partnerLoading;
        break;
      case SET_PARTNER:
        draft.partner = action.partner;
        break;
      case SET_PAGES:
        draft.pages = action.pages;
        break;
      default:
        return;
    }
  });
};

export default partnerReducer;
