import { store } from "App";
import axios from "axios";
import { dispatchSetUserCountry } from "modules/Account/actions";
import { dispatchSetFlacStream, dispatchToggleFlacStream, dispatchUpdateIsFlacEnabled } from "modules/Config/actions";
import api from "utils/apiManager";
import { pendoInitHandler } from "utils/pendo";
import { fetchLang, getIsFlacStream, isLoggedIn } from "utils/storeUtils";
import { getHeaders } from "..";
import { getCancelSubUrl, getClientUrl, getDeleteAccountUrl, getSetAudioFormatUrl, getUserCountryUrl, refreshClientCacheUrl } from "../urls";
import { captureException } from "@sentry/react";

export const apiLibraryGetClient = async (tokenProp) => {
  const lang = fetchLang();
  const headers = tokenProp
    ? {
        Authorization: tokenProp,
      }
    : getHeaders();
  const getClient = getClientUrl(lang);

  try {
    const resp = await axios.get(getClient, { headers });
    store.dispatch(dispatchSetFlacStream(resp?.data?.streamQualityWeb === "flac"));
    store.dispatch(dispatchUpdateIsFlacEnabled(!!resp?.data?.allowFlac));
    localStorage.setItem("userLang", resp?.data?.customerLanguage || store.getState().partner.partner.defaultLanguage || "en");
    pendoInitHandler(resp?.data);
    return resp?.data;
  } catch (error) {
    captureException(error, {
      tags: {
        message: "Error - Client's API request failed",
      },
    });
    throw new Error(error);
  }
};

export const apiLibraryToggleAudioQuality = async (audioFormat) => {
  const headers = getHeaders();
  const setAudioFormatUrl = getSetAudioFormatUrl(audioFormat);

  try {
    await axios.post(setAudioFormatUrl, null, { headers });
  } catch (error) {}
};

export const handleToggleAudioQuality = () => {
  const isFlacStream = getIsFlacStream();
  apiLibraryToggleAudioQuality(isFlacStream === true ? "mp3" : "flac");
  store.dispatch(dispatchToggleFlacStream());
};
