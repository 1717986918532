import React, { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import reducers from "reducers";
import "styles/style.scss";
import SmallLoader from "components/SmallLoader";
import PartnerHOC from "partners/FocalContainer/PartnerHOC";

const FocalContainer = lazy(() => import("partners/FocalContainer"));
const devToolsParam = () => {
  const allowedDomains = ["http://localhost", "http://localhost:3000", "https://web2-stage.vialma.com", "https://pre-prod.vialma.com"];
  return allowedDomains.includes(process.env.REACT_APP_DOMAIN);
};

const store = configureStore({ reducer: reducers, devTools: devToolsParam() });

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<SmallLoader isPage />}>
        <PartnerHOC>
          <FocalContainer />
        </PartnerHOC>
      </Suspense>
    </Provider>
  );
};

if (window.Cypress) {
  window.store = store;
}

export default App;
export { store };
