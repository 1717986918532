import { createSelector } from "reselect";
import { REDUCER_KEY } from "./constants";

const getLang = (state) => state[REDUCER_KEY].lang;
const getLogin = (state) => state[REDUCER_KEY].isLogged;
const getOverflow = (state) => state[REDUCER_KEY].overflow;
const getSubscription = (state) => state[REDUCER_KEY].subscription;
const getShowFreeTrial = (state) => state[REDUCER_KEY].showFreeTrial;
const getShowLoginModal = (state) => state[REDUCER_KEY].showLoginModal;
const getShowMyCollectionModal = (state) => state[REDUCER_KEY].showMyCollectionModal;
const getShowClassicModal = (state) => state[REDUCER_KEY].showClassicModal;
const getShowSignupFreeTrialModal = (state) => state[REDUCER_KEY].showSignupFreeTrialModal;
const getShowSignupFreeTrialModalHeader = (state) => state[REDUCER_KEY].showSignupFreeTrialModalHeader;
const getAdventDataClassic = (state) => state[REDUCER_KEY].adventDataClassic;
const getAdventDataJazz = (state) => state[REDUCER_KEY].adventDataJazz;
const getAdventLang = (state) => state[REDUCER_KEY].adventLang;
const getIsJazzSelected = (state) => state[REDUCER_KEY].isJazzSelected;
const getFlacStream = (state) => state[REDUCER_KEY].isFlacStream;
const getFlacAllowed = (state) => state[REDUCER_KEY].isFlacAllowed;
const getAxiosLiveCalls = (state) => state[REDUCER_KEY].axiosLiveCalls;
const getShowOfferModal = (state) => state[REDUCER_KEY].showOfferModal;
const getCheckSubscriptionUpdate = (state) => state[REDUCER_KEY].checkSubscriptionUpdate;
const getContextMenuProps = (state) => state[REDUCER_KEY].contextMenuProps;
const getHeaderClicked = (state) => state[REDUCER_KEY].isHeaderClicked;

export const getLangSelector = createSelector(getLang, (lang) => lang);

export const getLoginSelector = createSelector(getLogin, (isLogged) => isLogged);

export const getOverflowSelector = createSelector(getOverflow, (overflow) => overflow);

export const getSubscriptionSelector = createSelector(getSubscription, (subscription) => subscription);

export const getShowFreeTrialSelector = createSelector(getShowFreeTrial, (showFreeTrial) => showFreeTrial);

export const getShowLoginModalSelector = createSelector(getShowLoginModal, (showLoginModal) => showLoginModal);

export const getShowMyCollectionSelector = createSelector(getShowMyCollectionModal, (showMyCollectionModal) => showMyCollectionModal);

export const getShowClassicModalSelector = createSelector(getShowClassicModal, (showClassicModal) => showClassicModal);

export const getShowSignupFreeTrialModalSelector = createSelector(getShowSignupFreeTrialModal, (showSignupFreeTrialModal) => showSignupFreeTrialModal);

export const getShowSignupFreeTrialModalHeaderSelector = createSelector(getShowSignupFreeTrialModalHeader, (showSignupFreeTrialModalHeader) => showSignupFreeTrialModalHeader);

export const getAdventDataClassicSelector = createSelector(getAdventDataClassic, (adventDataClassic) => adventDataClassic);

export const getAdventDataJazzSelector = createSelector(getAdventDataJazz, (adventDataJazz) => adventDataJazz);

export const getAdventLangSelector = createSelector(getAdventLang, (adventLang) => adventLang);

export const getIsJazzSelector = createSelector(getIsJazzSelected, (isJazzSelected) => isJazzSelected);

export const getFlacStreamSelector = createSelector(getFlacStream, (isFlac) => isFlac);

export const getFlacAllowedSelector = createSelector(getFlacAllowed, (isAllowed) => isAllowed);

export const getAxiosLiveCallsSelector = createSelector(getAxiosLiveCalls, (calls) => calls); 

export const getShowOfferModalSelector = createSelector(getShowOfferModal, (showOfferModal) => showOfferModal);

export const getCheckSubscriptionUpdateSelector = createSelector(getCheckSubscriptionUpdate, (checkSubscriptionUpdate) => checkSubscriptionUpdate);

export const getContextMenuPropsSelector = createSelector(getContextMenuProps, (contextMenuProps) => contextMenuProps);

export const getHeaderClickedSelector = createSelector(getHeaderClicked, (isHeaderClicked) => isHeaderClicked);



