import { accessRoutes } from "components/ApplicationRoutes/routes";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { CLASSICAL_NAME, JAZZ_NAME } from "./nameVariables";
import * as Sentry from "@sentry/react";
import { fetchLang, getUserCountry } from "./storeUtils";

const ROLES = {
  UNKNOWN: 0,
  COMPOSER: 1,
  PERFORMER: 2,
  CONDUCTOR: 3,
  ARRANGER: 4,
  ACCOMPANIMENT: 5,
  COMPOSER_JAZZ: 9,
  PERFORMER_JAZZ: 10,
  OTHER_PERFORMER_JAZZ: 11,
  ARTIST: 12,
  ORCHESTRA: 13,
  ENSEMBLE: 14,
  CHOIR: 15,
  ENGINEER: 16,
  PRODUCER: 17,
  EDITOR: 18,
  LYRICIST: 19,
  NA: 20,
  SOLOIST: 21,
  WRITER: 22,
  FEATUREDARTIST: 23,
  MUSICPUBLISHER: 24,
  FEATURING: 25,
  COMPOSERLYRICIST: 26,
};

const PERFORMERS_LIST = [2, 5, 10, 11, 12, 21, 23];
const ENSEMBLE_LIST = [13, 14, 15];

const getUniverseFromTrack = (track) => {
  if (has(track, "track_metadata.universes") && !isEmpty(track.track_metadata.universes)) {
    return track.track_metadata.universes[0].name.toLowerCase();
  }
};

const isTrackJazz = (track) => {
  try {
    if (has(track, "track_metadata.universes") && !isEmpty(track.track_metadata.universes)) {
      return track.track_metadata.universes[0].name.toLowerCase() === JAZZ_NAME.toLowerCase();
    }
  } catch (error) {
    Sentry.captureException(error, {
      track: {
        track_id: track.track_metadata.id,
      },
    });
    return false;
  }
};


const getTitleFromTrack = (track) => {
  return has(track, "track_metadata.title") ? track.track_metadata.title : "";
};

const getTrackIdFromTrack = (track) => {
  return has(track, "track_metadata.id") ? track.track_metadata.id : "";
};

const getWorkFromTrack = (track) => {
  if (has(track, "track_metadata.work") && !isEmpty(track.track_metadata.work)) {
    return track.track_metadata.work;
  }
};

const getComposerFromTrack = (track) => {
  if (has(track, "track_metadata.work.composers") && !isEmpty(track.track_metadata.work.composers)) {
    const composer = track.track_metadata.work.composers[0];
    return composer;
  }
};

const getDisplayArtistFromTrack = (track) => {
  if (has(track, "track_metadata.actors.displayArtist") && !isEmpty(track.track_metadata.actors.displayArtist)) {
    const displayArtist = track.track_metadata.actors.displayArtist;
    return displayArtist;
  }
};

const getArtistsFromTrack = (track) => {
  if (has(track, "track_metadata.actors.artists")) {
    return track.track_metadata.actors.artists.map((artist) => {
      return artist.name;
    });
  }
};


const getJazzPerformersFromTrack = (track, lang) => {
  const lngRoutes = accessRoutes[lang];

  if (has(track, "track_metadata.actors.artists")) {
    const performers = track.track_metadata.actors.artists.filter((artist) => artist.roles.some((role) => PERFORMERS_LIST.includes(role.id)));

    return performers.map((performer) => ({
      ...performer,
      url: lngRoutes ? `${lngRoutes.jazzArtistsDetails}/${performer.id}/${performer.slug}` : ``,
    }));
  }
};

const getClassicalPerformersFromTrack = (track, lang) => {
  const lngRoutes = accessRoutes[lang];

  if (has(track, "track_metadata.actors.artists")) {
    const performers = track.track_metadata.actors.artists.filter((artist) => artist.roles.some((role) => PERFORMERS_LIST.includes(role.id)));
    const composer = getComposerFromTrack(track);
    const composerId = composer && composer.id;

    const performersWithoutComposer = performers.filter((perf) => perf.id !== composerId);

    return performersWithoutComposer.map((performer) => ({
      ...performer,
      url: lngRoutes ? `${lngRoutes.personPage}/${performer.id}/${performer.slug}`: ``,
    }));
  }
};

const getClassicalImageFromTrack = (track) => {
  return {
    imageRootUrl: track?.track_metadata?.work?.composers[0]?.imageRootUrl,
    imagePath: track?.track_metadata?.work?.composers[0]?.imagePath,
  };
};

const getJazzAlbumImageFromTrack = (track) => {
  return {
    imageRootUrl: track?.track_metadata?.album?.imageRootUrl,
    imagePath: track?.track_metadata?.album?.imagePath,
  };
};

const getLabelFromTrack = (track) => {
  if (has(track, "track_metadata") && !isEmpty(track.track_metadata)) {
    return track.track_metadata.label;
  }
};

const getAlbumFromTrack = (track) => {
  if (has(track, "track_metadata.album") && !isEmpty(track.track_metadata.album)) {
    return track.track_metadata.album;
  }
};

const getRecordYear = (track) => {
  if (has(track, "track_metadata.interpretationYear")) {
    return track.track_metadata.interpretationYear;
  }
  return "";
};

const getAlbumSlug = (item) => {
  const lang = fetchLang();
  const { album_metadata, slug: rootSlug } = item;
  const { slugs, slug } = album_metadata || "";
  const slugStr = slugs && slugs[lang] ? slugs[lang] : slug ? slug : rootSlug;
  return slugStr;
};

const getAlbumDetails = (album = {}) => {
  const { album_metadata } = album;
  return {
    ...album,
    ...album_metadata,
  };
};

const getAritstString = (artists) => {
  let artistString = "";
  if (!isEmpty(artists)) {
    return (artistString = artists
      .map((artist) => {
        return artist.name.trim();
      })
      .join(` • `));
  }
  return artistString;
};

const getAlbumDisplayArtist = (item) => {
  const { album_metadata, displayArtist } = item;
  return album_metadata?.actors?.displayArtist?.name || displayArtist?.name;
};

const isCountryAllowed = (track) => {
  const userCountry = getUserCountry();
  let resp = true;
  const validityCountries = track?.track_metadata?.validityCountries ?? track?.validityCountries;
  if (validityCountries && userCountry) {
    resp =
      validityCountries.some((country) => country.countryCode === userCountry.twoLetterCode || country.countryCode === "WW") ||
      isEmpty(validityCountries);
  }
  return resp;
};

export {
  ROLES,
  getTitleFromTrack,
  getJazzPerformersFromTrack,
  getClassicalPerformersFromTrack,
  getClassicalImageFromTrack,
  getTrackIdFromTrack,
  getRecordYear,
  getJazzAlbumImageFromTrack,
  getComposerFromTrack,
  getWorkFromTrack,
  getUniverseFromTrack,
  getArtistsFromTrack,
  getAlbumFromTrack,
  getLabelFromTrack,
  getDisplayArtistFromTrack,
  isTrackJazz,
  getAlbumDetails,
  getAlbumSlug,
  isCountryAllowed,
  getAritstString,
  getAlbumDisplayArtist,
};
