import { SET_PAGES, SET_PARTNER, SET_PARTNER_LOADING } from "./constants";

export const dispatchSetPartner = (partner) => ({
  type: SET_PARTNER,
  partner,
});

export const dispatchSetPartnerLoading = (partnerLoading) => ({
  type: SET_PARTNER_LOADING,
  partnerLoading,
});

export const dispatchSetPartnerPages = (pages) => ({
  type: SET_PAGES,
  pages,
});
