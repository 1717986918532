import { captureException } from "@sentry/react";
import { getHeaders } from "utils/apiLibrary";
import { getPageBySlug, getCollectionWidget, getPartnerById, getPartnerPages, getPartnerPagesWidgets, getWidgetContent, updateClientLanguage } from "./urls";
import axios from "axios";
import partnerGetDefaultLang from "../partnerGetDefaultLang";
import { fetchLang } from "utils/storeUtils";

const adaptResponse = (response) => {
  return {
    id: response?.id,
    configuration: response?.configuration,
    name: response?.name?.toUpperCase(),
    customerGroup: {
      id: response?.configuration?.customer_group?.id,
      name: response?.configuration?.customer_group?.name,
    },
    languages: response?.languages?.map((lang) => ({
      id: lang.id,
      name: lang.name,
      shortCode: lang.shortcode,
    })),
    defaultLanguage: response?.default_language?.shortcode,
    allowSelfServiceAccountCreation: response?.configuration?.account_creation,
    colours: {
      siteBackgroundColour: response?.theme?.colour?.site_bg_clr,
      titlesColour: response?.theme?.colour?.titles_clr,
      CtaButtonsTextColor: response?.theme?.colour?.cta_buttons_clr,
      ctaButtonsBgColor: response?.theme?.colour?.cta_buttons_bg_clr,
      widgetInteractionsPrimaryText: response?.theme?.colour?.interactions_pr_txt_clr,
      widgetInteractionsSecondaryText: response?.theme?.colour?.interactions_sc_txt_clr,
      widgetInteractionsPrimaryBg: response?.theme?.colour?.interactions_pr_clr,
      widgetInteractionsSecondaryBg: response?.theme?.colour?.interactions_sc_clr,
      siteWidePrimaryTextColour: response?.theme?.colour?.site_wide_pr_txt_clr,
      siteWideSecondaryTextColour: response?.theme?.colour?.site_wide_sc_txt_clr,
    },
    logo: {
      logo: response?.logo?.path,
      imageRootUrl: response?.logo?.path.split("/").slice(0, -1).join("/"),
      imagePath: "/" + response?.logo?.path.split("/").slice(-3).join("/"),
    },
    favicon: {
      favicon: response?.favicon?.path,
      imageRootUrl: response?.favicon?.path.split("/").slice(0, -1).join("/"),
      imagePath: "/" + response?.favicon?.path.split("/").slice(-3).join("/"),
    },
  };
};
export const partnerApiLibraryGetPageBySlug = async (slug) => {
  const headers = getHeaders();
  const getPageBySlugUrl = getPageBySlug(slug);
  try {
    if (!slug) throw new Error("No slug in request");
    const resp = await axios.get(getPageBySlugUrl, { headers });
    return resp.data.data;
  } catch (error) {
    captureException(error, {
      tags: {
        message: "Error - API request failed",
      },
    });
    return error;
  }
};

export const partnerApiLibraryGetPartnerById = async () => {
  const headers = getHeaders();
  const currentPathname = window.location.pathname.split("/");
  const currentPossibleLang = currentPathname[1];
  let lang = currentPossibleLang;
  const getPartnerByIdUrl = getPartnerById;
  try {
    const resp = await axios.get(getPartnerByIdUrl, { headers });
    const modifiedResponse = adaptResponse(resp.data);
    return modifiedResponse;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      window.location.href = `${process.env.REACT_APP_DEFAULT_WEBSITE_URL}`;
    }
    captureException(error, {
      tags: {
        message: "Error - Partners API request failed",
      },
    });
    console.error(error);
  }
};
export const partnerApiLibraryGetPartnerByPages = async (id) => {
  const headers = getHeaders();
  const currentPathname = window.location.pathname.split("/");
  const currentPossibleLang = currentPathname[1];
  let lang = currentPossibleLang;
  const getPartnerByIdUrl = getPartnerPages(id);
  try {
    const resp = await axios.get(getPartnerByIdUrl, { headers });
    return resp.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      window.location.href = `${process.env.REACT_APP_DEFAULT_WEBSITE_URL}`;
    }
    captureException(error, {
      tags: {
        partnerId: id,
        message: "Error - API request failed",
      },
    });
    console.error(error);
  }
};
export const partnerApiLibraryGetPartnerByPagesWidgets = async (id, pageId) => {
  const headers = getHeaders();
  const currentPathname = window.location.pathname.split("/");
  const currentPossibleLang = currentPathname[1];
  let lang = currentPossibleLang;
  const getPartnerByIdUrl = getPartnerPagesWidgets(id, pageId);
  try {
    const resp = await axios.get(getPartnerByIdUrl, { headers });
    return resp.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      window.location.href = `${process.env.REACT_APP_DEFAULT_WEBSITE_URL}`;
    }
    captureException(error, {
      tags: {
        partnerId: id,
        pageId: pageId,
        message: "Error - API request failed",
      },
    });
    console.error(error);
  }
};
export const partnerApiLibraryGetCollectionWidget = async (id,widgetId) => {
  const headers = getHeaders();
  const getPartnerByIdUrl = getCollectionWidget(id, widgetId);
  try {
    const resp = await axios.get(getPartnerByIdUrl, { headers });
    return resp.data;
  } catch (error) {
    captureException(error, {
      tags: {
        partnerId: id,
        widgetId: widgetId,
        message: "Error - API request failed",
      },
    });
  }
};
export const partnerApiLibraryGetWidgetContent = async (id, widgetId) => {
  const headers = getHeaders();
  const getWidgetContentUrl = getWidgetContent(id, widgetId);
  try {
    if (!id) throw new Error("No id in request");
    const resp = await axios.get(getWidgetContentUrl, { headers });
    return resp.data;
  } catch (error) {
    captureException(error, {
      tags: {
        partnerId: id,
        widgetId: widgetId,
        message: "Error - API request failed",
      },
    });
  }
};

export const partnerApiLibraryUpdateClientlanguage = async (language) => {
  const headers = getHeaders();
  try {
    if (!language) throw new Error("No language in request");
    const resp = await axios.post(updateClientLanguage, { language: language }, { headers });
    return resp.data.data;
  } catch (error) {
    captureException(error, {
      tags: {
        language: language,
        message: "Error - Change-language API request failed",
      },
    });
    return error;
  }
};
