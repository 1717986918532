import { combineReducers } from "redux";
import miniPlayerReducer from "./modules/MiniPlayer/reducer";
import configReducer from "./modules/Config/reducer";
import composersReducer from "./modules/Composers/reducer";
import worksReducer from "./modules/Works/reducer";
import radiosReducer from "./modules/Radios/reducer";
import libraryReducer from "./modules/Library/reducer";
import accountReducer from "./modules/Account/reducer";
import albumsJazzReducer from "./modules/AlbumsJazz/reducer";
import artistsReducer from "./modules/Artists/reducer";
import favouritesReducer from "./modules/Favourites/reducer";
import partnerReducer from "./modules/Partner/reducer";

const rootReducer = combineReducers({
  miniPlayer: miniPlayerReducer,
  config: configReducer,
  composers: composersReducer,
  works: worksReducer,
  radios: radiosReducer,
  library: libraryReducer,
  account: accountReducer,
  albumsJazz: albumsJazzReducer,
  artists: artistsReducer,
  favourites: favouritesReducer,
  partner: partnerReducer,
});

export default rootReducer;
