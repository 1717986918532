export const REDUCER_KEY = "config";
export const UPDATE_LANG = "UPDATE_LANG";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const SET_OVERFLOW = "SET_OVERFLOW";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SHOW_FREE_TRIAL = "UPDATE_SHOW_FREE_TRIAL";
export const UPDATE_SHOW_LOGIN_MODAL = "UPDATE_SHOW_LOGIN_MODAL";
export const UPDATE_SHOW_CLASSIC_MODAL = "UPDATE_SHOW_CLASSIC_MODAL";
export const UPDATE_SHOW_SIGNUP_FREE_TRIAL_MODAL = "UPDATE_SHOW_SIGNUP_FREE_TRIAL_MODAL";
export const UPDATE_ADVENT_DATA_CLASSIC = "UPDATE_ADVENT_DATA_CLASSIC";
export const UPDATE_ADVENT_DATA_JAZZ = "UPDATE_ADVENT_DATA_JAZZ";
export const UPDATE_UNIVERSE_SELECTION = "UPDATE_UNIVERSE_SELECTION";
export const TOGGLE_FLAC_STREAM = "TOGGLE_FLAC_STREAM";
export const SET_FLAC_STREAM = "SET_FLAC_STREAM";
export const SET_FLAC_ISENABLED = "SET_FLAC_ISENABLED";
export const INCREASE_AXIOS_LIVE_CALLS = "INCREASE_AXIOS_LIVE_CALLS";
export const DECREASE_AXIOS_LIVE_CALLS = "DECREASE_AXIOS_LIVE_CALLS";
export const UPDATE_SHOW_OFFER_MODAL = "UPDATE_SHOW_OFFER_MODAL";
export const CHECK_SUBSCRIPTION_UPDATE = "CHECK_SUBSCRIPTION_UPDATE";
export const SET_CONTEXT_MENU_PROPS = "SET_CONTEXT_MENU_PROPS";
export const UPDATE_SHOW_MY_COLLECTION_MODAL = "UPDATE_SHOW_MY_COLLECTION_MODAL";
export const UPDATE_HEADER_CLICKED = "UPDATE_HEADER_CLICKED"
